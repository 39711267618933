import { IOrgCollection } from '@custom-types/org-collection/org-collection.model';
import { createAction, props } from '@ngrx/store';

export const fetchOrgCollectionList = createAction('[Org Collection] Fetch All');

export const setFetchedOrgCollectionList = createAction(
  '[Org Collection] Set Fetched Collection List',
  props<{
    collectionList: IOrgCollection[];
  }>(),
);
export const setSelectedOrgCollection = createAction(
  '[Org Collection] Set Selected Collection',
  props<{
    collectionUuid: string;
  }>(),
);
export const setOrgCollectionToAllCollections = createAction('[Org Collection] Set Collection To All Collections');
