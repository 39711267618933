import { SiteEnergyComponent } from './components/site-energy/site-energy.component';
import { VerticalCompositionBars } from './components/site-energy/vertical-composition-bars/vertical-composition-bars.component';
import { PortfolioOverviewChartComponent } from './components/portfolio-overview-chart/portfolio-overview-chart.component';
import { InviteUserPage } from './pages/modals/invite-user/invite-user.page';
import { AlertDefinitionComponent } from './pages/unit-view/unit-alerts/alert-definition/alert-definition.component';
import { AlertComponent } from './pages/unit-view/unit-alerts/alert/alert.component';
import { AddEditAlertDefinitionModalPage } from './pages/modals/add-edit-alert-definition-modal/add-edit-alert-definition-modal.page';
import { AlertModalPage } from './pages/modals/alert-modal/alert-modal.page';
import { PortfolioCardComponent } from './components/portfolio-card/portfolio-card.component';
import { HomeListComponent } from './components/home-list/home-list.component';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { UnitOverviewSvgComponent } from './components/unit-overview-svg/unit-overview-svg.component';
import { SystemStatusComponent } from './components/system-status/system-status.component';
import { WeatherComponent } from './components/weather/weather.component';
import { FormsModule } from '@angular/forms';
import { UnitAnalyticComponent } from './components/unit-analytic/unit-analytic.component';
import { UnitAnalyticLegendComponent } from './components/unit-analytic-legend/unit-analytic-legend.component';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { SwitchdinDeviceStreamComponent } from './components/switchdin-device-stream/switchdin-device-stream.component';
import { LiveMetricGraphComponent } from './components/live-metric-graph/live-metric-graph.component';
import { ElementLoaderComponent } from './components/element-loader/element-loader.component';
import { CarbonSavingsComponent } from './components/carbon-savings/carbon-savings.component';
import { BatteryStatusComponent } from './components/battery-status/battery-status.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';

import { MatSortModule } from '@angular/material/sort';

import { MatIconModule } from '@angular/material/icon';

import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NoDataComponent } from './components/no-data/no-data.component';
import { ErrorComponent } from './components/error/error.component';
import { VppStatusUnitsComponent } from './components/vpp-status-units/vpp-status-units.component';
import { VppStateOperationGraphComponent } from './components/vpp-state-operation-graph/vpp-state-operation-graph.component';
import { VppOperationTabStreamingWidgetComponent } from './components/vpp-operation-tab-streaming-widget/vpp-operation-tab-streaming-widget.component';
import { VppOperationTabControlGroupComponent } from './components/vpp-operation-tab-control-group/vpp-operation-tab-control-group.component';
import { VppOperationTabFutureTableComponent } from './components/vpp-operation-tab-future-table/vpp-operation-tab-future-table.component';
import { VppComplexSchedulesComponent } from './components/vpp-complex-schedules/vpp-complex-schedules.component';

import { VppControlGroupSchedulesComponent } from './components/vpp-control-group-schedules/vpp-control-group-schedules.component';
import { VppOperationHistoryComponent } from './components/vpp-operation-history/vpp-operation-history.component';
import { VppOperationHistoryTableComponent } from './components/vpp-operation-history-table/vpp-operation-history-table.component';
import { RawDataComponent } from './components/raw-data/raw-data.component';
import { UnitDeviceEventsComponent } from './components/unit-device-events/unit-device-events.component';
import { AddNewControllerComponent } from './components/add-new-controller/add-new-controller.component';
import { DeviceDetailComponent } from './components/device-detail/device-detail.component';
import { ControllerDetailComponent } from './components/controller-detail/controller-detail.component';
import { DeviceDetailModalPage } from './pages/modals/device-detail-modal/device-detail-modal.page';
import { ControllerDetailModalPage } from './pages/modals/controller-detail-modal/controller-detail-modal.page';
import { ControlGroupUnitsComponent } from './components/control-group-units/control-group-units.component';
import { BottomToolbarComponent } from './components/bottom-toolbar/bottom-toolbar.component';
import { SiteEnergyCardComponent } from './components/site-energy/site-energy-card/site-energy-card.component';
import { ComparisonChartCardComponent } from './components/site-energy/comparison-chart-card/comparison-chart-card.component';

// directives
import { MustMatchDirective } from './directives/validators/must-match.directive';
import { CheckSubStringExistDirective } from './directives/validators/check-sub-string-exist.directive';
import { UnitCardComponent } from './components/unit-card/unit-card.component';
import { StatusCircleComponent } from './components/status-circle/status-circle.component';
import { DateLabelPipe } from './pipes/date-label.pipe';
import { AppUpdatesNotificationComponent } from './components/app-updates-notification/app-updates-notification.component';
import { NotificationAlertComponent } from './components/notification-alert/notification-alert.component';
import { IfHasPermissionDirective } from './directives/permissions/if-has-permission.directive';
import { DeviceControlComponent } from './components/device-control/device-control.component';
import { DropletListComponent } from './components/droplet-list/droplet-list.component';
import { DropletComponent } from './components/droplet/droplet.component';
import { DropletItemDetailComponent } from './components/droplet-item-detail/droplet-item-detail.component';
import { MqttStatusComponent } from './components/mqtt-status/mqtt-status.component';
import { DeviceDetailMessageComponent } from './components/device-detail-message/device-detail-message.component';
import { DevicesServiceStatusComponent } from './components/devices-service-status/devices-service-status.component';
import { InputWithInlineButtonComponent } from './components/inputs/input-with-inline-button/input-with-inline-button.component';
import { ResyncDeviceMessageButtonComponent } from './components/resync-device-message-button/resync-device-message-button.component';
import { VppOperationSidebarComponent } from './components/vpp-operation-sidebar/vpp-operation-sidebar.component';
import { DropletFirmwareUpgradePage } from './pages/modals/droplet-firmware-upgrade/droplet-firmware-upgrade.page';
import { DropletFirmwareUpdateButtonComponent } from './components/droplet-firmware-update-button/droplet-firmware-update-button.component';
import { DropletControllerInventoryComponent } from './components/droplet-controller-inventory/droplet-controller-inventory.component';
import { DropletNotificationComponent } from './components/droplet-notification/droplet-notification.component';
import { ModalContainerComponent } from './components/modal/modal-container/modal-container.component';
import { ModalToolbarComponent } from './components/modal/modal-toolbar/modal-toolbar.component';
import { VppModeToRaiseDemandTypeStringPipe } from './pipes/vpp/vpp-mode-to-raise-demand-type-string.pipe';
import { VppModeToLowerDemandTypeStringPipe } from './pipes/vpp/vpp-mode-to-lower-demand-type-string.pipe';
import { VppSettingControlGroupListComponent } from './components/vpp-setting-control-group-list/vpp-setting-control-group-list.component';
import { VppEditControlGroupModalComponent } from './pages/modals/vpp-edit-control-group-modal/vpp-edit-control-group-modal.component';
import { VppOperationNemPricesComponent } from './components/vpp-operation-nem-prices/vpp-operation-nem-prices.component';
import { VppSettingControlTargetComponent } from './components/vpp-setting-control-target/vpp-setting-control-target.component';
import { VppGeneralSettingsComponent } from './components/vpp-general-settings/vpp-general-settings.component';
import { VppGetUnitDevicesGivenUnitIdPipe } from './pipes/vpp/vpp-get-unit-devices-given-unit-id.pipe';
import { FilterSelectComponent } from './components/filter-select/filter-select.component';
import { VppTimezoneLocalePipe } from './pipes/vpp/vpp-timezone-locale.pipe';
import { UserSettingComponent } from './components/user-setting/user-setting.component';
import { ProfileComponent } from './components/profile/profile.component';

import { PauseButtonComponent } from './components/buttons/pause-button/pause-button.component';
import { ResumeButtonComponent } from './components/buttons/resume-button/resume-button.component';
import { CreateButtonComponent } from './components/buttons/create-button/create-button.component';
import { CreateRectButtonComponent } from './components/buttons/create-rect-button/create-rect-button.component';
import { RetryButtonComponent } from './components/buttons/retry-button/retry-button.component';
import { UpdateButtonComponent } from './components/buttons/update-button/update-button.component';
import { EditButtonComponent } from './components/buttons/edit-button/edit-button.component';
import { BackOutlineButtonComponent } from './components/buttons/back-outline-button/back-outline-button.component';
import { RemoveButtonComponent } from './components/buttons/remove-button/remove-button.component';
import { CancelOutlineButtonComponent } from './components/buttons/cancel-outline-button/cancel-outline-button.component';
import { SaveRectButtonComponent } from './components/buttons/save-rect-button/save-rect-button.component';
import { InputStackedLabelComponent } from './components/inputs/input-stacked-label/input-stacked-label.component';
import { InputSelectStackedLabelComponent } from './components/inputs/input-select-stacked-label/input-select-stacked-label.component';
import { InputSelectStackedLabelWithIconComponent } from './components/inputs/input-select-stacked-label-with-icon/input-select-stacked-label-with-icon.component';
import { InputSelectComponent } from './components/inputs/input-select/input-select.component';
import { InputComponent } from './components/inputs/input/input.component';
import { InputTextareaStackedLabelComponent } from './components/inputs/input-textarea-stacked-label/input-textarea-stacked-label.component';
import { InputValidationErrorsComponent } from './components/inputs/input-validation-errors/input-validation-errors.component';
import { PoweredByFooterComponent } from './components/powered-by-footer/powered-by-footer.component';
import { EnrollInEnrolledButtonComponent } from './components/buttons/enroll-in-enrolled-button/enroll-in-enrolled-button.component';
import { EnvironmentConfigsComponent } from './components/environment-configs/environment-configs.component';
import { StateWrapperComponent } from './components/state-wrapper/state-wrapper.component';
import { SkeletonItemsComponent } from './components/skeletons-placeholders/skeleton-items/skeleton-items.component';
import { PortfolioCreateAndEditModalComponent } from './pages/modals/portfolio-create-and-edit-modal/portfolio-create-and-edit-modal.component';
import { SearchFilterPipe } from './pipes/core/search-filter.pipe';
import { SubTabsComponent } from './components/sub-tabs/sub-tabs.component';
import { UnitMetricSelectionTypeheadComponent } from './components/unit-metric-selection-typehead/unit-metric-selection-typehead.component';
import { UnitAlertRecipientsTypeheadComponent } from './components/unit-alert-recipients-typehead/unit-alert-recipients-typehead.component';
import { ComparisonChartComponent } from './components/site-energy/comparison-chart/comparison-chart.component';
import { ButtonComponent } from './components/buttons/button/button.component';
import { LetDirective } from '@ngrx/component';
import { PushPipe } from '@ngrx/component';
import { DeviceAutomatedTestingComponent } from './components/device-automated-testing/device-automated-testing.component';
import { AppToastNotificationsComponent } from '@custom-component/UI/core/app-toast-notifications/app-toast-notifications.component';
import { SideMenuComponent } from '@custom-component/side-menu/side-menu.component';

const IMPORT_AND_EXPORT = [
  AppToastNotificationsComponent,
  SideMenuComponent,
  PoweredByFooterComponent,
  IfHasPermissionDirective,
];

@NgModule({
  declarations: [
    AlertComponent,
    AlertDefinitionComponent,
    UnitOverviewSvgComponent,
    FilterSelectComponent,
    SystemStatusComponent,
    StatusCircleComponent,
    MqttStatusComponent,
    WeatherComponent,
    UnitAnalyticComponent,
    UnitAnalyticLegendComponent,
    LiveMetricGraphComponent,
    DateLabelPipe,
    VppModeToRaiseDemandTypeStringPipe,
    SwitchdinDeviceStreamComponent,
    ElementLoaderComponent,
    AppHeaderComponent,
    PortfolioCardComponent,
    HomeListComponent,
    NoDataComponent,
    ErrorComponent,
    VppStatusUnitsComponent,
    VppStateOperationGraphComponent,
    VppOperationSidebarComponent,
    VppOperationNemPricesComponent,
    VppOperationTabStreamingWidgetComponent,
    VppOperationTabControlGroupComponent,
    VppOperationTabFutureTableComponent,
    VppComplexSchedulesComponent,
    VppControlGroupSchedulesComponent,
    VppSettingControlGroupListComponent,
    VppSettingControlTargetComponent,
    VppGeneralSettingsComponent,
    AppUpdatesNotificationComponent,
    NotificationAlertComponent,
    UnitCardComponent,
    VppOperationHistoryComponent,
    VppOperationHistoryTableComponent,
    RawDataComponent,
    UnitDeviceEventsComponent,
    AddNewControllerComponent,
    DeviceControlComponent,
    InputWithInlineButtonComponent,
    MustMatchDirective,
    CheckSubStringExistDirective,
    InviteUserPage,
    DropletListComponent,
    DropletComponent,
    DropletItemDetailComponent,
    DevicesServiceStatusComponent,
    DeviceDetailComponent,
    DeviceDetailMessageComponent,
    ResyncDeviceMessageButtonComponent,
    ControllerDetailComponent,
    PortfolioOverviewChartComponent,
    AddEditAlertDefinitionModalPage,
    AlertModalPage,
    CarbonSavingsComponent,
    BatteryStatusComponent,
    DeviceDetailModalPage,
    ControllerDetailModalPage,
    VppEditControlGroupModalComponent,
    SiteEnergyComponent,
    VerticalCompositionBars,
    SiteEnergyCardComponent,
    ComparisonChartCardComponent,
    ControlGroupUnitsComponent,
    BottomToolbarComponent,
    EditButtonComponent,
    DropletFirmwareUpgradePage,
    DropletFirmwareUpdateButtonComponent,
    DropletControllerInventoryComponent,
    DropletNotificationComponent,
    VppModeToLowerDemandTypeStringPipe,
    VppGetUnitDevicesGivenUnitIdPipe,
    VppTimezoneLocalePipe,
    UserSettingComponent,
    ProfileComponent,
    PauseButtonComponent,
    ResumeButtonComponent,
    ModalContainerComponent,
    ModalToolbarComponent,
    CreateButtonComponent,
    CreateRectButtonComponent,
    RetryButtonComponent,
    UpdateButtonComponent,
    RemoveButtonComponent,
    SaveRectButtonComponent,
    CancelOutlineButtonComponent,
    BackOutlineButtonComponent,
    InputStackedLabelComponent,
    InputSelectStackedLabelComponent,
    InputSelectStackedLabelWithIconComponent,
    InputSelectComponent,
    ModalContainerComponent,
    ModalToolbarComponent,
    InputComponent,
    InputTextareaStackedLabelComponent,
    EnrollInEnrolledButtonComponent,
    EnvironmentConfigsComponent,
    PortfolioCreateAndEditModalComponent,
    StateWrapperComponent,
    SkeletonItemsComponent,
    SearchFilterPipe,
    SubTabsComponent,
    UnitMetricSelectionTypeheadComponent,
    UnitAlertRecipientsTypeheadComponent,
    ComparisonChartComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatExpansionModule,
    DragDropModule,
    LetDirective,
    PushPipe,
    ButtonComponent,
    DeviceAutomatedTestingComponent,
    InputValidationErrorsComponent,
    ...IMPORT_AND_EXPORT,
  ],
  exports: [
    AlertComponent,
    AlertDefinitionComponent,
    FormsModule,
    ReactiveFormsModule,
    SwitchdinDeviceStreamComponent,
    LiveMetricGraphComponent,
    UnitOverviewSvgComponent,
    SystemStatusComponent,
    StatusCircleComponent,
    MqttStatusComponent,
    WeatherComponent,
    FilterSelectComponent,
    UnitAnalyticComponent,
    TranslateModule,
    ElementLoaderComponent,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    DragDropModule,
    MatExpansionModule,
    AppHeaderComponent,
    PortfolioCardComponent,
    HomeListComponent,
    NoDataComponent,
    ErrorComponent,
    VppStatusUnitsComponent,
    VppStateOperationGraphComponent,
    VppOperationNemPricesComponent,
    VppOperationSidebarComponent,
    VppOperationTabStreamingWidgetComponent,
    VppOperationTabControlGroupComponent,
    VppOperationTabFutureTableComponent,
    VppComplexSchedulesComponent,
    VppControlGroupSchedulesComponent,
    VppSettingControlGroupListComponent,
    VppSettingControlTargetComponent,
    VppGeneralSettingsComponent,
    AppUpdatesNotificationComponent,
    NotificationAlertComponent,
    UnitCardComponent,
    VppOperationHistoryComponent,
    VppOperationHistoryTableComponent,
    RawDataComponent,
    UnitDeviceEventsComponent,
    AddNewControllerComponent,
    InputWithInlineButtonComponent,
    DeviceControlComponent,
    MustMatchDirective,
    CheckSubStringExistDirective,
    DropletListComponent,
    DropletComponent,
    DropletItemDetailComponent,
    DevicesServiceStatusComponent,
    DeviceDetailComponent,
    DeviceDetailMessageComponent,
    ResyncDeviceMessageButtonComponent,
    ControllerDetailModalPage,
    SiteEnergyComponent,
    ControllerDetailComponent,
    PortfolioOverviewChartComponent,
    AddEditAlertDefinitionModalPage,
    AlertModalPage,
    VppEditControlGroupModalComponent,
    CarbonSavingsComponent,
    BatteryStatusComponent,
    DeviceDetailModalPage,
    VerticalCompositionBars,
    SiteEnergyCardComponent,
    ControlGroupUnitsComponent,
    ComparisonChartCardComponent,
    BottomToolbarComponent,
    EditButtonComponent,
    DropletFirmwareUpgradePage,
    DropletFirmwareUpdateButtonComponent,
    VppTimezoneLocalePipe,
    UserSettingComponent,
    ProfileComponent,
    PauseButtonComponent,
    ResumeButtonComponent,
    ModalContainerComponent,
    ModalToolbarComponent,
    CreateButtonComponent,
    CreateRectButtonComponent,
    RemoveButtonComponent,
    RetryButtonComponent,
    SaveRectButtonComponent,
    CancelOutlineButtonComponent,
    BackOutlineButtonComponent,
    InputStackedLabelComponent,
    InputSelectStackedLabelComponent,
    InputSelectStackedLabelWithIconComponent,
    InputSelectComponent,
    InputComponent,
    InputTextareaStackedLabelComponent,
    InputValidationErrorsComponent,
    EnrollInEnrolledButtonComponent,
    EnvironmentConfigsComponent,
    PortfolioCreateAndEditModalComponent,
    StateWrapperComponent,
    SkeletonItemsComponent,
    SearchFilterPipe,
    SubTabsComponent,
    UnitMetricSelectionTypeheadComponent,
    UnitAlertRecipientsTypeheadComponent,
    ComparisonChartComponent,
    LetDirective,
    PushPipe,
    ...IMPORT_AND_EXPORT,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
