import { OrgUnitSummary, OrganisationUnit } from '@custom-types/org-settings/org-unit.model';
import { UnitOperationalStateApiOrgFilters } from '@custom-types/unit/unit-operational-state-api.model';
import { createAction, props } from '@ngrx/store';

export const fetchAllOrgSettingsUnits = createAction(
  '[Org Settings Unit] Fetch All',
  props<{
    cursor: string;
    search: string;
    unit_operational_state: UnitOperationalStateApiOrgFilters[];
    collection_uuid: string;
    collection_name: string;
  }>(),
);
export const setFetchedOrgSettingsUnits = createAction(
  '[Org Settings Unit] Set Fetched',
  props<{
    next: string | null;
    previous: string | null;
    units: OrganisationUnit[];
  }>(),
);
export const setOrgSettingsUnitLoadingState = createAction(
  '[Org Settings Unit] Set Org Unit Loading State',
  props<{ isLoading: boolean; isError?: boolean; error?: Error | null }>(),
);
export const fetchOrgSummary = createAction('[Org Settings Unit] Fetch Org Summary');
export const setFetchedOrgSummary = createAction(
  '[Org Settings Unit] Set Org Summary',
  props<{
    summary: OrgUnitSummary;
  }>(),
);
export const setOrgSummaryLoadingState = createAction(
  '[Org Settings Unit] Set Org Summary Loading State',
  props<{ isLoading: boolean; isError?: boolean; error?: Error | null }>(),
);
