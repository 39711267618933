import {
  setFetchedOrgCollectionList,
  setOrgCollectionToAllCollections,
  setSelectedOrgCollection,
} from './org-collection.action';
import { createReducer, on } from '@ngrx/store';
import { IOrgCollection } from '@custom-types/org-collection/org-collection.model';

export const ALL_COLLECTIONS: IOrgCollection = {
  uuid: 'all',
  name: 'All Collections',
  description: 'All collections',
  deletable: false,
  default: false,
};

export interface OrgCollectionState {
  orgCollectionList: IOrgCollection[];
  selectedOrgCollection: IOrgCollection | null;
}

export const initialState: OrgCollectionState = {
  orgCollectionList: [],
  selectedOrgCollection: ALL_COLLECTIONS,
};

export const appOrgCollectionReducer = createReducer(
  initialState,
  on(setFetchedOrgCollectionList, (state, { collectionList }) => {
    const orgCollectionList = [ALL_COLLECTIONS, ...collectionList];

    return {
      ...state,
      orgCollectionList,
    };
  }),
  on(setSelectedOrgCollection, (state, { collectionUuid }) => {
    const selectedOrgCollection =
      state.orgCollectionList.find((collection) => collection.uuid === collectionUuid) || null;

    return {
      ...state,
      selectedOrgCollection,
    };
  }),
  on(setOrgCollectionToAllCollections, (state) => {
    return {
      ...state,
      selectedOrgCollection: ALL_COLLECTIONS,
    };
  }),
);
