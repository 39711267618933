@if (isLoggedIn$ | async) {
  <ion-menu side="start" contentId="main-content">
    <ion-content id="my-content">
      <ion-grid class="full-height">
        <ion-row class="full-height">
          <!-- primary menu -->
          <ion-col size="12" class="ion-align-self-start ion-text-center">
            <ion-item lines="none">
              <ion-img class="menu-logo" [src]="headerLogoPath$ | async" [alt]="headerLogoAltName$ | async" />
            </ion-item>

            @if ((userOrganisation$ | async)?.length > 0) {
              <ion-item id="trigger-button" lines="none" class="b-1 b-radius-8 ion-margin org-name-bkg">
                @if ((userOrganisation$ | async).length === 1) {
                  <ion-label class="text-primary-xl ion-text-capitalize">
                    {{ (selectedUserOrganisation$ | async).name }}
                  </ion-label>
                } @else {
                  <ion-select
                    interface="popover"
                    (ionChange)="handleUnitFilterChange($event)"
                    [value]="(selectedUserOrganisation$ | async).uuid"
                    color="text-primary"
                    class="text-primary-xl ion-text-capitalize"
                    label-placement="floating"
                  >
                    @for (org of userOrganisation$ | async; track org.uuid) {
                      <ion-select-option [value]="org.uuid" class="text-primary-lg">
                        {{ org.name }}
                      </ion-select-option>
                    }
                  </ion-select>
                }
              </ion-item>
            }
            <!-- Home -->
            <ion-item
              class="main-items"
              lines="full"
              detail="false"
              [routerLink]="['home']"
              routerLinkActive="link-active"
            >
              <ion-icon [src]="homeIcon" slot="start"></ion-icon>
              <ion-label>
                {{ 'Admin.Home' | translate }}
              </ion-label>
            </ion-item>

            <!-- Portfolio Monitoring -->
            <ion-item
              class="main-items"
              lines="full"
              detail="false"
              *ifHasPermission="[PermissionKey.GLOBAL_PORTFOLIO, PermissionKey.GLOBAL_UNIT]"
              [routerLink]="['portfolios']"
              routerLinkActive="link-active"
            >
              <ion-icon [src]="portfolioIcon" slot="start"></ion-icon>
              <ion-label>
                {{ 'General.PortfolioMonitoring' | translate }}
              </ion-label>
            </ion-item>

            @if (!isPlatformCordova) {
              <!-- Virtual Power Plant -->
              <ion-item
                class="main-items"
                lines="full"
                detail="false"
                *ifHasPermission="[PermissionKey.GLOBAL_VPP]"
                [routerLink]="['virtual_power_plants']"
                routerLinkActive="link-active"
              >
                <ion-icon [src]="vppIcon" slot="start"></ion-icon>
                <ion-label>
                  {{ 'VirtualPowerPlant.VirtualPowerPlants' | translate }}
                </ion-label>
              </ion-item>
            }

            <!-- Installer -->
            <ion-item
              class="main-items"
              lines="full"
              detail="false"
              *ifHasPermission="[PermissionKey.GLOBAL_INSTALLER]"
              [routerLink]="['configure']"
              routerLinkActive="link-active"
            >
              <ion-icon [src]="installerIcon" slot="start"></ion-icon>
              <ion-label>
                {{ 'Admin.Installer' | translate }}
              </ion-label>
            </ion-item>
          </ion-col>

          <!-- secondary menu -->
          <ion-col size="12" class="ion-align-self-end ion-text-center pb-0">
            @if ((userOrganisation$ | async)?.length > 0) {
              <!-- Organisation Settings -->
              <ion-item
                class="main-items"
                detail="false"
                lines="none"
                *ifHasPermission="[PermissionKey.GLOBAL_ORGANISATION]"
              >
                <ion-icon [src]="orgSettingIcon" slot="start"></ion-icon>
                <ion-label>
                  {{ 'Organisation.OrganisationSettings' | translate }}
                </ion-label>
              </ion-item>

              <!-- Organisation Settings -> Unit List -->
              <ion-item
                class="main-items"
                detail="false"
                lines="full"
                *ifHasPermission="[PermissionKey.GLOBAL_ORGANISATION]"
                [routerLink]="['organisation-settings', (selectedUserOrganisation$ | async)?.uuid, 'unit-list']"
                routerLinkActive="link-active"
              >
                <ion-icon slot="start"></ion-icon>
                <ion-label> {{ 'Menu.UnitList' | translate }} </ion-label>
              </ion-item>
            }

            <!-- Support -->
            <ion-item
              class="main-items cursor-pointer"
              lines="full"
              detail="false"
              href="https://support.switchdin.com/hc/en-us"
              target="_blank"
            >
              <ion-icon [src]="supportIcon" slot="start"></ion-icon>
              <ion-label>
                {{ 'Menu.Support' | translate }}
              </ion-label>
            </ion-item>

            <!-- User -->
            <ion-item class="main-items cursor-pointer" lines="full" detail="false" (click)="presentProfileModal()">
              <ion-icon [src]="userIcon" slot="start"></ion-icon>
              <ion-label>
                {{ userFriendlyName$ | async }}
              </ion-label>
            </ion-item>

            <!-- Logout -->
            <ion-item class="main-items cursor-pointer" lines="full" detail="false" (click)="logout()">
              <ion-icon [src]="logoutIcon" slot="start"></ion-icon>
              <ion-label>
                {{ 'Menu.SignOut' | translate }}
              </ion-label>
            </ion-item>

            <div class="pt-15">
              <ion-text class="text-medium fs-15">v{{ version }}</ion-text>
              <app-powered-by-footer [showColoredLogo]="'true'"></app-powered-by-footer>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer class="ion-text-center ion-padding-vertical">
      <ion-row>
        <ion-col>
          <a [href]="privacyPolicy" target="_blank" rel="noopener noreferrer" class="text-deco-none text-medium">
            {{ 'Admin.PrivacyPolicy' | translate }}
          </a>
          <span>|</span>
          <a [href]="termsConditions" target="_blank" rel="noopener noreferrer" class="text-deco-none text-medium">
            {{ 'Admin.TermsAndConditions' | translate }}
          </a>
        </ion-col>
      </ion-row>
    </ion-footer>
  </ion-menu>
}
