export interface ThemeNameValue {
  name: string;
  value: string;
}

export enum ThemeMap {
  ASFG_JACANA = 'asfg-jacana',
  AUSGRID = 'ausgrid',
  EGUANA = 'eguana',
  EVO_POWER = 'evo-power',
  RAA = 'raa',
  RED_EARTH = 'redearth',
  SIMPLY_ENERGY = 'simply-energy',
  SONNEN = 'sonnen',
  SWITCHDIN = 'switchdin-rebrand',
  YURIKA = 'yurika',
  POWER_PLUS_ENERGY = 'power-plus-energy',
  SYMPHONY = 'symphony',
  GM_ENERGY = 'gm-energy',
}

export const DEFAULT_THEME_NAME = ThemeMap.SWITCHDIN;

/**
 * THEMES is an array that consists of pairs of ThemeNames and ThemeValues
 * [{name: AUSGRID, value: ausgrid}]
 * using this in profile
 * name is being used to read the translation text from translation file
 * value is getting sent to backend
 */
export const THEMES: ThemeNameValue[] = Object.entries(ThemeMap).map(
  (theme: [string, string]): ThemeNameValue => ({ name: theme[0], value: theme[1] }),
);

enum ThemeLogos {
  DARK_MODE = 'dark-mode-logo.svg',
  LIGHT_MODE = 'light-mode-logo.svg',
}

export enum ThemeFooterIcons {
  DEFAULT = 'powered-by-switchdin.svg',
  OTHER_THEMES = 'stormcloud-powered-by-switchdin.svg',
}

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum FolderName {
  LIGHT = 'light-mode',
  DARK = 'dark-mode',
}

export enum AppAssets {
  APP_ICONS = '/assets/app-icons/',
  INTERNATIONALIZE = '/assets/i18n/',
  ICONS = '/assets/icons/',

  ICONS_SVG_LIGHT = '/assets/icons/svg/',
  ICONS_SVG_DARK = '/assets/icons/svg/dark-mode/',

  ICONS_SVG_MENU = '/assets/icons/svg/menu/',
  ICONS_SVG_WEATHER = '/assets/icons/svg/weather-icons/',

  THEME_ICONS = '/assets/theme-icons/',

  FOOTER_LIGHT = '/assets/footer/light-mode/',
  FOOTER_DARK = '/assets/footer/dark-mode/',
}

/**
 * Creating path from where we need to pick the logos
 * in assets => theme-icons folder, all the name of the folders should match ThemeMap values
 * e.g., eguana, ausgrid, giant-red, etc.
 */

export const getThemeIconsLight = THEMES.reduce((acc, theme) => {
  acc[theme.value] = `${AppAssets.THEME_ICONS}${theme.value}/${ThemeLogos.LIGHT_MODE}`;
  return acc;
}, {});
export const getThemeIconsDark = THEMES.reduce((acc, theme) => {
  acc[theme.value] = `${AppAssets.THEME_ICONS}${theme.value}/${ThemeLogos.DARK_MODE}`;
  return acc;
}, {});

export enum SvgIconNames {
  BACK_ARROW = 'back-arrow.svg',
  HAMBURGER_FILL = 'hamburger-fill.svg',
  HOME = 'home.svg',
  HOME_WITHOUT_S = 'home-without-s.svg',
  CAST = 'cast.svg',
  DEVICES = 'devices.svg',
  GRAPHIC_EQ = 'graphic-eq.svg',
  LOCATION_CITY = 'location-city.svg',
  LOGOUT = 'logout.svg',
  PERMISSIONS = 'permissions.svg',
  SELECT = 'select.svg',
  FAILED_MESSAGE = 'failed-message.svg',
  SWD_INSTALLER_DROPLET = 'swd-installer-droplet.svg',
  INFO = 'info.svg',
  TRASH = 'trash.svg',
  POWER_OFF = 'power-off.svg',
  POWER_OFF_WHITE = 'power-off-white.svg',
  POWER_WHITE = 'power-white.svg',
  SETTING_BACKUP_RESTORE_WHITE = 'settings-backup-restore-white.svg',
  SETTINGS = 'settings.svg',
  HELP_CIRCLE = 'help-circle.svg',
  USER = 'user.svg',
  SEARCH = 'search.svg',
  WIFI = 'wifi.svg',
  CHECK_MARK_INSTALLER = 'check-mark-installer.svg',
  GREEN_DOTS = 'green-dots.svg',
  BLUE_LIGHT = 'blue-light.svg',
  BEST_WIFI = 'best.svg',
  GOOD_WIFI = 'good.svg',
  POOR_WIFI = 'poor-wifi.svg',
  ALERT_WARNING = 'alert-warning-icon.svg',
  ADD_INSTALLER = 'add-installer.svg',
  CHARTS = 'charts.svg',
  PLUS = 'plus.svg',
  EDIT = 'edit.svg',
  FORWARD = 'forward.svg',
}
