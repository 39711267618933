import { PermissionStore } from '@class/commons/permissions/permission-constants';
import { ActionReducerMap } from '@ngrx/store';
import { appUserPermissionsReducer } from './permissions/permissions.reducers';
import { ToastInputSettings } from '@custom-types/core/app-toast-notifications.model';
import { appToastNotificationsReducer } from './app-toast-notifications/app-toast-notifications.reducers';
import {
  OrgSettingsUnitState,
  appOrgSettingsUnitReducer,
} from './org-settings/org-settings-unit/org-settings-unit.reducers';
import {
  UserOrgMembershipsState,
  appUserOrgMembershipsReducer,
} from './user/user-organisation-membership/user-organisation-membership.reducers';
import { InstallerState, appInstallerReducer } from './installer/installer.reducers';
import { appOrgCollectionReducer, OrgCollectionState } from './org-settings/org-collection/org-collection.reducers';

export interface AppState {
  userPermissions: PermissionStore | null;
  appToastNotifications: ToastInputSettings | null;
  appOrgSettingsUnits: OrgSettingsUnitState | null;
  appUserOrgMemberships: UserOrgMembershipsState | null;
  appOrgCollection: OrgCollectionState | null;
  appInstaller: InstallerState | null;
}

export const appStateReducers: ActionReducerMap<AppState> = {
  userPermissions: appUserPermissionsReducer,
  appToastNotifications: appToastNotificationsReducer,
  appOrgSettingsUnits: appOrgSettingsUnitReducer,
  appUserOrgMemberships: appUserOrgMembershipsReducer,
  appOrgCollection: appOrgCollectionReducer,
  appInstaller: appInstallerReducer,
};
